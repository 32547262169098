@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&display=swap');

html {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
  /* colors */
  --black: #111111;
  --dark-gray: #181818;
  --medium-gray: #2b2a2a;
  --light-gray: #7d7d7d;
  --gray-white: #EBE9E3;
  --green: #84EEA2;
  --lighter-green: #51f07e;
  --darker-green: #2a9749; 
  --blueish-purple: #738ad4;
  /* font & font-weights */
  --site-font: 'Montserrat', sans-serif;
  --h1: 900;
  --h2: 800;
  --h3: 700;
  --body: 600;
  /* font-sizes */
  --home-header: 3.125rem;
  --home-subheader: 1.9rem;
  --home-link: 1.56rem;
  --section-header: 1.94rem;
  --site-link: 1.44rem;
  /* --about-body: 1.25rem; */
  --project-summary-name: 1.69rem;
  --project-summary-description: 1.4rem;
  --project-page-body: 1.25rem;
  --contact-header-and-btn: 1.56rem;
  --contact-message: 1.125rem;
  --nav-header: 1.1rem;
  --nav-menu: 1.94rem;
  --nav-menu-desktop: 1.3rem;
  --nav-contact: 0.875rem;
}

*, *::after, *::before {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--black);
  color: var(--gray-white);
  font-family: var(--site-font);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  scroll-behavior: smooth;
  -webkit-scroll-behavior: smooth;
}

a {
  text-decoration: none;
  color: var(--green);
  font-weight: var(--h3);
}

button {
  background-color: var(--green);
  color: var(--black);
  border: none;
  border-radius: 20px;
  font-weight: var(--h1);
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* ---page container--- */

.container {
  margin: 1.25em;
  padding-top: 4em;
  max-width: 1099px;
}
.container:nth-of-type(1) {
  padding-top: 0;
}
.container:nth-last-of-type(1) {
  padding-bottom: 4em;
}

/* ---navbar--- */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 75px;
  border-bottom: solid 3px;
  border-color: var(--dark-gray);
  background-color: var(--black);
  top: 0;
  width: 100%;
  padding: 0 1.25em;
  position: sticky;
  z-index: 100;
}
.navbar__contact {
  padding: .5em 1em;
  font-family: inherit;
  font-size: var(--nav-contact);
  font-weight: var(--h1);
  color: var(--black);
  background-color: var(--green);
  border-radius: 20px;
}
.navbar__menu {
  position: fixed;
  width: 100%;
  height: 395px;
  padding: 0 1.25em;
  background-color: var(--dark-gray);
  top: -100%;
  right: 0;
  left: 0;
  transition: 600ms;
}
.navbar__menu.active {
  top: 75px;
  transition: 600ms;
}
.navbar__menu-header {
  font-weight: var(--h1);
  color: var(--light-gray);
  font-size: var(--nav-header);
}
.navbar__menu-items {
  list-style: none;
  padding-left: 0;
  height: 73%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: var(--nav-menu);
  font-weight: var(--h2);
}
.navbar__menu-items li a {
  color: var(--gray-white);
}
.navbar__burger {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 55px;
  width: 55px;
  border-radius: 50%;
}
.navbar__burger-btn {
  width: 18px;
  height: 3px;
  background-color: var(--gray-white);
  border-radius: 5px;
  transition: all 500ms ease-in-out;
}
.navbar__burger-btn::before, .navbar__burger-btn::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background-color: var(--gray-white);
  transition: all 500ms ease-in-out;
}
.navbar__burger-btn::before {
  transform: translateY(-10px);
}
.navbar__burger-btn::after {
  transform: translateY(10px);
}
.navbar__burger.open .navbar__burger-btn {
  background-color: transparent;
}
.navbar__burger.open .navbar__burger-btn::before {
  transform: rotate(45deg);  
}
.navbar__burger.open .navbar__burger-btn::after {
  transform: rotate(-45deg);
}
.navbar__menu-large-screen {
  display: none;
}

/* ---home--- */

.home__header {
  font-weight: var(--h1);
  font-size: var(--home-header);
  margin: 0 0 .65em 0;
  line-height: 1;
}
.home span {
  display: block;
}
.home__img {
  margin-bottom: 1.25em;
  border-radius: 50%;
  width: 35%;
}
.home__subheader {
  font-weight: var(--h3);
  font-size: var(--home-subheader);
  margin: 0;
  line-height: 1.35;
}
.home__subheader--dark {
  color: var(--light-gray);
}
.home__link {
  font-size: var(--home-link);
  display: block;
  margin-top: 1.5em;
  /* border: solid red; */
}
.home__link-icon {
  font-size: calc(var(--home-link) - .3em);
}

/* ---about me--- */

.about__header {
  font-weight: var(--h1);
  font-size: var(--section-header);
}
.about__body {
  font-size: var(--about-body);
  color: var(--light-gray);
  line-height: 1.5;
  margin-top: 0;
}
.about__body:nth-last-of-type(1) {
  margin-bottom: 2em;
}
.about__link {
  display: block;
  font-size: var(--site-link);
}
.about__link-icon {
  font-size: calc(var(--site-link) - .1em);
}

/* ---recent projects--- */

.recent-projects__header {
  font-weight: var(--h1);
  font-size: var(--section-header)
}
.project-summary {
  margin: 3.5em 0;
  line-height: 1.4;
}
.project-summary:nth-of-type(1) {
  margin-top: 2.5em;
}
.project-summary:nth-last-of-type(1) {
  margin-bottom: 0;
}
.project-summary__name {
  font-weight: var(--h2);
  font-size: var(--project-summary-name);
}
.project-summary__description {
  color: var(--light-gray);
  font-size: var(--project-summary-description);
  margin-top: 0;
}
.project-summary__description span {
  color: var(--gray-white);
}
.project-summary__thumbnail {
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 1em;
}
.project-summary__link {
  display: block;
  font-size: var(--site-link);
  margin-bottom: .5em;
}
.project-summary__link-icon {
  font-size: calc(var(--site-link) - .2em);
}

/* ---project details--- */

.project-details__header {
  font-weight: var(--h1);
  font-size: var(--section-header)
}
.project-details__screenshot {
  max-width: 100%;
  border-radius: 10px;
}
.project-details__description {
  color: var(--light-gray);
  font-size: var(--project-summary-description);
  line-height: 1.4;
}
.project-details__link {
  display: block;
  font-size: var(--site-link);
  margin-bottom: .5em;
}

/* ---contact--- */

.contact__header {
  font-weight: var(--h1);
  font-size: var(--section-header)
}
.contact__handles {
  width: fit-content;
}
.contact__link {
  font-size: var(--site-link);
}
.contact__link-type {
  text-transform: uppercase;
  font-weight: var(--h2);
  margin-bottom: -1.1em;
}
.contact__link-type-icon {
  vertical-align: middle;
  margin-bottom: .2em;
}
.contact__link-handle {
  font-size: var(--site-link);
  color: var(--light-gray);
  margin-bottom: 1.5em;
  width: fit-content;
}
.contact__message-header {
  font-weight: var(--h3);
  font-size: var(--contact-header-and-btn);
}
.contact__message-form {
  display: flex;
  flex-direction: column;
  color: var(--light-gray);
}
.contact__message-form input, .contact__message-form textarea {
  display: block;
  width: 100%;
  border: none;
  background-color: var(--medium-gray);
  border-radius: 20px;
  margin-top: .5em;
  font-family: var(--site-font);
  font-size: var(--contact-message);
  color: var(--gray-white);
  padding: 1em;
}
.contact__message-form > * {
  margin-bottom: 1em;
}
.contact__message-form button {
  font-family: inherit;
  font-size: var(--contact-header-and-btn);
  font-weight: --h1;
  padding: .5em;
  margin-top: .5em;
}

.loading-spinner {
  margin: auto;
  border: 5px solid #57c576;
  border-radius: 50%;
  border-top: 5px solid var(--darker-green);
  width: 30px;
  height: 30px;
  animation: spinner 1s linear infinite;
}
@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg);}
}

@media (min-width: 768px) {

  html {
    /* font-sizes */
    --home-header: 5rem;
    --home-subheader: 2.5rem;
    --home-link: 2rem;
    --section-header: 2.4rem;
    --site-link: 1.6rem;
    --about-body: 1.25rem;
    --project-summary-name: 2rem;
    --contact-header-and-btn: 1.56rem;
    --nav-contact: 1rem;
  }

  /* ---page container--- */

  .container {
    margin: 1.25em 3em;
  }

  /* ---navbar--- */

  .navbar {
    padding: 0 2em;
  }
  .navbar__menu {
    padding: 0 2.25em;
  }

}

@media (min-width: 1024px) {

  html {
    /* font-sizes */
    --home-subheader: 2rem;
    --home-link: 1.7rem;
    --site-link: 1.4rem;
    --section-header: 2.7rem;
  }

  main a {
    transition: 300ms;
    width: fit-content;
  }

  main a:hover, a:focus {
    transform: scale(1.05);
    color: var(--lighter-green);
  }

  main a:active {
    color: var(--darker-green);
    transform: translateY(2px);
  }

  /* ---navbar--- */

  .navbar {
    justify-content: center;
  }
  .navbar__contact {
    transition: 300ms;
    display: none;
  }
  .navbar__contact-large-screen {
    display: block;
  }
  .navbar__contact:hover, .navbar__contact:focus {
    background-color: var(--lighter-green);
    transform: scale(1.05);
  }
  .navbar__contact:active {
    transform: translateY(2px)
  }
  .navbar__burger, .navbar__menu {
    display: none;
  }
  .navbar__menu-large-screen {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1099px;
  }
  .navbar__menu-items {
    height: 100%;
    width: 400px;
    flex-direction: row;
    font-size: var(--nav-menu-desktop);
  }
  .navbar__menu-items li {
    transition: 300ms;
  }

  .navbar__menu-items li:hover, .navbar__menu-items li:focus {
    transform: scale(1.05);
  }

  .navbar__menu-items li a:hover, .navbar__menu-items li a:focus {
    color: var(--green);
    transform: scale(1.05);
  }

  .navbar__menu-items li:active {
    color: var(--darker-green);
    transform: translateY(2px);
  }

  .navbar__menu-large-screen .navbar__contact:focus {
    color: var(--black);
  }

  /* .active-scroll-spy {
    padding-bottom: .3em;
    border-bottom: 3px solid var(--green);
  }

  .navbar__menu-large-screen .navbar__contact.active-scroll-spy {
    background-color: var(--light-gray);
    padding-bottom: .5em;
    border-bottom: none;
  } */


  /* ---page container--- */

  .container {
    margin: 2em 3em;
  }

  /* ---home--- */

  .home {
    display: grid;
    grid-template: 3fr 3fr 1fr/1fr 2fr 2fr;
    column-gap: 1em;
  }
  .home span {
    display: inline;
  }
  .home__header {
    grid-row: 1;
    grid-column: 1/4;
  }
  .home__img {
    width: 70%;
    grid-row: 2/4;
    grid-column: 1/2;
    margin-top: 1em;
  }
  .home__subheader {
    grid-row: 2/3;
    grid-column: 2/3;
    align-self: flex-start;
  }
  .home__subheader--dark {
    grid-row: 3/4;
    grid-column: 2/3;
    margin-top: -2.35em;
  }
  .home__link {
    margin-top: .2em;
    grid-row: 2/3;
    grid-column: 3/4;
    justify-self: center;
    height: fit-content;
    padding: 0 1em 1em 1em;
  }
  .home__link:hover, .home__link:focus {
    transform: scale(1.15);
    color: var(--lighter-green);
  }
  .home__link:active {
    transform: translateY(2px);
  }

  /* ---about--- */

  .about__header {
    margin-bottom: 0.5em;
  }
  .about__body-full {
    display: flex;
  }
  .about__body {
    width: 100%;
  }
  .about__body:nth-of-type(1) {
    margin-right: 2em;
  }

  /* ---recent projects--- */

  .recent-projects__container {
    display: grid;
    grid-template: 1fr 1fr/ 1fr 1fr;
    gap: 3em;
  }
  .project-summary {
    margin: 0;
  }
  .project-summary:nth-of-type(1) {
    margin-top: 0;
  }

  /* ---contact--- */

  .contact {
    display: grid;
    grid-template: 2.7rem 1fr 1fr / 1fr 2fr;
    row-gap: 2.5em;
  }
  .contact__header {
    grid-row: 1;
    grid-column: 1/3;
  }
  .contact__handles {
    grid-row: 2/4;
    grid-column: 1/2;
  }
  .contact__link {
    font-size: calc(var(--site-link) + .3rem);
  }
  .contact__link-type {
    margin-bottom: -.9em;
  }
  .contact__message {
    grid-row: 2/4;
    grid-column: 2/3;
  }
  .contact__link-type {
    transition: 300ms;
    width: fit-content;
  }
  .contact__link-type:hover, .contact__link:focus {
    transform: scale(1.05);
  }
  .contact__message-form button {
    padding: .5em 0;
    width: 175px;
    margin-top: .5em;
    align-self: flex-end;
    cursor: pointer;
    transition: 300ms;
  }
  .contact__message-form button:hover {
    background-color: var(--lighter-green);
    transform: scale(1.05);
  }
  .contact__message-form button:active {
    transform: translateY(2px)
  }
  .contact__check-icon {
    vertical-align: middle;
    margin-top: -.2em;
  }

}

